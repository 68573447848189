<template>
  <div>
    <v-subheader>{{ checkoutStep.title }}</v-subheader>
    <div v-if="checkoutSectionInstances !== null && checkoutSectionInstances.length > 0">
      <template v-for="(instance, index) in checkoutSectionInstances">
        <v-list-item
          :key="instance.namespace"
        >
          <v-list-item-action
            class="drag-handle"
            style="cursor: all-scroll"
          >
            <v-icon>mdi-drag</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ instance.title }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon>
              <v-icon>mdi-settings</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="index" />
      </template>
    </div>
    <v-list-item v-else>
      <v-list-item-content>
        <v-list-item-title>{{$t('empty')}}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>
<script>
export default {
    name: 'CheckoutStep',
    props: {
      checkoutStep: {
        type: Object,
        default: () => {}
      },
      checkoutSectionInstances: {
        type: Array,
        default: () => []
      }
    }
}
</script>
